.facilities-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  column-gap: 100px; /* Space between items */
}
.facility-item {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
}

/* For screens smaller than 900px, use two columns */
@media (max-width: 900px) {
  .facilities-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
  }
}

/* For screens smaller than 600px, use one column */
@media (max-width: 600px) {
  .facilities-container {
    grid-template-columns: 1fr; /* One column */
  }
}

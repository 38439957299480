/* General Gallery Section */
.image-gallery-section {
  padding: 2rem 0;
  /* background-color: #f9f9f9; */
  padding-top: 6rem;
}

/* Container styling */
.gallery-container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Main Gallery Image Styling */
.image-gallery .image-gallery-slide img {
  width: 100%;
  height: 600px; /* Regular height */
  object-fit: cover; /* Maintain aspect ratio and fill the height */
}

/* Fullscreen Mode Adjustments */
.image-gallery.fullscreen .image-gallery-slide img {
  height: auto; /* Remove the fixed height in fullscreen */
  object-fit: contain; /* Ensure the image fits within the screen without cropping */
}

/* Thumbnail Styling */
.image-gallery-thumbnails .image-gallery-thumbnail img {
  height: 100px; /* Thumbnail height */
  object-fit: cover; /* Maintain aspect ratio and fill the height */
}

/* Zoom effect on hover for main gallery image */
/* .image-gallery img:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
} */

/* Responsive adjustments */
@media (max-width: 768px) {
  .image-gallery .image-gallery-slide img {
    height: 250px; /* Regular height on smaller screens */
  }

  .image-gallery-thumbnails .image-gallery-thumbnail img {
    height: 60px; /* Thumbnail height on smaller screens */
  }

  /* Fullscreen Mode Adjustments for Smaller Screens */
  .image-gallery.fullscreen .image-gallery-slide img {
    height: auto; /* Remove the fixed height in fullscreen on smaller screens */
  }
}

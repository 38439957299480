.header {
  background-color: transparent;
  color: #000;
  padding: 1rem 0;
  padding-right: 32rem !important;
  position: fixed;
  height: 80px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  justify-items: end;
}
.header-blur {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(227 96 45 / 19%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(16px);
}
.header-container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1; /* Ensure content inside header has higher z-index */
}

.logo {
  margin-left: 1rem;
}

.logo-svg {
  width: 150px;
  height: auto;
}

.nav {
  display: flex;
  align-items: center;
}

.nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1; /* Ensure menu items are above backdrop */
}

/* .nav ul li {
  margin: 0px 2px;
} */

.nav ul li a {
  color: #000; /* Default text color */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s, background-color 0.3s;
  padding: 0.5rem 1rem; /* Adds padding for better click area */
  border-radius: 10px; /* Rounded background */
  font-weight: bold;
}

.nav ul li a.selected {
  color: #fff; /* Text color for the selected item */
  background-color: #0c1c26; /* Background color for the selected item */
}

.nav ul li a:hover {
  color: #0c1c26; /* Color change on hover */
}

.nav ul li a.selected:hover {
  color: #fff; /* Text color for the selected item */
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1100; /* Ensure hamburger icon is above everything */
}

.hamburger i {
  color: #0c1c26; /* Default color for the icon */
  transition: color 0.3s ease;
}

.nav.open ~ .hamburger i {
  color: #0c1c26; /* Change color to white when the menu is open */
}

.reservation-button {
  background-color: #e3602d; /* Button color */
  color: #000; /* Text color */
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s;
}

.scroll-arrow {
  font-size: 2rem;
  position: absolute;
  bottom: 25px;
  z-index: 1050;
  color: white;
  text-shadow: 0 0 2px black;
}
.reservation-button:hover {
  background-color: #0c1c26; /* Darker shade on hover */
  color: white;
}

.social-icons-container {
  z-index: 1000;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: -8px;
  margin-right: 8px;
}

.social-icon i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: #0c1c26;
}
@media (max-width: 968px) {
  .logo {
    margin: auto;
  }
  .nav {
    display: none;
    position: fixed;
    top: 0;
    right: -80%; /* Start off-screen to the right */
    width: 80%;
    height: 100%;
    background-color: #86c3b1;
    transition: right 0.3s ease;
    padding-top: 4rem;
    z-index: 999; /* Ensure nav slides over the backdrop */
    box-shadow: 0 0 4px black;
  }

  .nav.open {
    right: 0; /* Slide into view */
    display: flex;
    flex-direction: column;
  }

  .nav ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .nav ul li {
    margin: 1rem 2rem;
  }

  .nav ul li a {
    font-size: 1.2rem;
    color: #0c1c26;
    text-decoration: none;
    transition: color 0.3s;
  }

  .nav ul li a:hover {
    color: #0c1c26;
  }

  .hamburger {
    display: block;
    z-index: 1100;
  }

  header > .social-icons-container {
    display: none;
  }

  nav > .social-icons-container {
    margin-right: 0;
    margin-top: 4rem;
  }
  .reservation-button {
    margin-top: 2rem;
    margin-left: 0;
  }
}

@media (min-width: 968px) {
  nav > .social-icons-container {
    display: none;
  }
}

.campaigns-section {
  padding-top: 6rem;
}

.campaigns-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.btn-campaigns {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  color: #e3602d;
}
.campaign-card {
  /* background-color: #faca6f; */
  background-color: #86c3b173;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: calc(
    33.333% - 1rem
  ); /* Adjust width to fit 3 cards per row with margin */
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.campaign-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.campaign-card:hover .campaign-image {
  transform: scale(1.1); /* Zoom effect */
}

.campaign-info {
  padding: 1rem;
}

.campaign-title {
  font-size: 1.25rem;
  margin: 0 0 0.5rem;
}

.campaign-description {
  font-size: 1rem;
  color: #555;
}

.campaign-icon {
  font-size: 4rem;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.popup-content {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  max-height: 80vh;
  color: black;
  overflow-y: auto; /* Allows scrolling inside the popup */
}

.popup-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  background-color: #fff; /* Ensure the header background covers the content when scrolling */
  z-index: 10;
  padding: 0.5rem;
}

.close-popup {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-text {
  padding: 2rem;
}

.popup-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: justify; /* Text justification for both sides */
}

.popup-container h4,
.popup-container h5 {
  text-align: center; /* Center align headings */
}

.popup-container ul {
  list-style-type: disc;
  margin-left: 2rem;
}

.popup-container p {
  margin: 0.5rem 0;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .campaign-card {
    width: calc(100%); /* One card per row on smaller screens */
  }
}

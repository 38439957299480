@keyframes zoomAnimation {
  0% {
    transform: scale(1) translate(0, 0); /* Start with no movement */
  }
  25% {
    transform: scale(1.15) translate(50px, 50px); /* Slight movement in both directions */
  }
  50% {
    transform: scale(1.3) translate(80px, 30px); /* Maximum zoom and movement */
  }
  75% {
    transform: scale(1.15) translate(50px, 50px); /* Move back to the other direction */
  }
  100% {
    transform: scale(1) translate(0, 0); /* Reset to the original position */
  }
}

.hero-section {
  height: calc(100vh - 0px);
  position: relative;
}

.hero-slider {
  height: calc(100vh - 0px);
}

.hero-slider .swiper-container {
  height: 100%;
}

.hero-slider .swiper-slide img {
  height: 100%;
  width: calc(100% + 100px);
  margin-left: -50px;
  object-fit: cover; /* Ensures the image covers the container */
}
.scroll-arrow {
  font-size: 2rem;
  position: absolute;
  bottom: 25px;
  z-index: 1050;
  color: white;
  text-shadow: 0 0 2px black;
}
.swiper {
  height: 100%;
}

.zoom-image {
  animation: zoomAnimation 26s linear infinite;
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the image covers the slide */
}

.warning {
  font-size: large;
  color: red;
  margin-top: -500px;
  padding: 1rem;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 999;
  position: relative;
  background: white;
  font-weight: bold;
}

.no-select {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (max-width: 968px) {
  .scroll-arrow {
    bottom: 100px;
  }
}

.footer {
  background-color: #0c1c26;
  color: #fff;
  padding: 2rem 0 0.5rem 0;
  margin-top: 8rem;
}

.container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info {
  flex: 1;
  margin: 0 1rem;
}

.contact-info h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;
}

.contact-item i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  color: #e3602d;
}

.contact-item p {
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

.contact-info a {
  color: #fff;
  text-decoration: none; /* Remove underline by default */
}

.contact-info a:hover {
  text-decoration: none; /* Ensure no underline on hover */
}

.whatsapp-btn {
  display: inline-block;
  background-color: #128c7e; /* Updated green tone */
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.whatsapp-btn:hover {
  background-color: #0c6b5d; /* Slightly darker green */
}

.map {
  flex: 1;
  margin: 0 1rem;
}

.map h3 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.map iframe {
  border: 0;
  border-radius: 8px;
}

.agreements-list {
  list-style-type: none;
  padding: 0;
}

.agreements-list li {
  margin-bottom: 0.5rem;
}

.agreements-list button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
}

.agreements-list button:hover {
  color: #e3602d;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.popup-content {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  max-height: 80vh;
  color: black;
  overflow-y: auto; /* Allows scrolling inside the popup */
}

.popup-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  background-color: #fff; /* Ensure the header background covers the content when scrolling */
  z-index: 10;
  padding: 0.5rem;
}

.close-popup {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-text {
  padding: 2rem;
}

.popup-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: justify; /* Text justification for both sides */
}

.popup-container h4,
.popup-container h5 {
  text-align: center; /* Center align headings */
}

.popup-container ul {
  list-style-type: disc;
  margin-left: 2rem;
}

.popup-container p {
  margin: 0.5rem 0;
}
.footer-header {
  color: #e3602d;
}
h1 {
  text-align: center;
  margin-bottom: 20px;
}
.licence-text {
  margin-top: 4rem;
  padding: 0;
  font-size: 12px;
}
.content {
  margin: 0 auto;
  max-width: 800px;
}
.section {
  margin-bottom: 20px;
}
.section h2 {
  font-weight: bold;
  margin-bottom: 10px;
}
.section p {
  margin: 0;
}
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .map {
    margin: 1rem 0;
    text-align: center;
  }

  .map iframe {
    height: 200px;
  }
}

@media (max-width: 968px) {
  .contact-info h3 {
    margin-top: 4.25rem;
  }
}

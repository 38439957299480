.whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.whatsapp-button {
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
  position: absolute;
  right: 0;
  bottom: 0;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

.whatsapp-dropdown {
  position: absolute;
  bottom: 70px;
  right: 0;
  background-image: url("/src/assets/images/whatsapp-bg.png");
  background-size: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 1001;
  width: 90%;
  max-width: 400px;
}

.whatsapp-header {
  background-color: rgb(246, 246, 246);
  padding: 8px;
  box-shadow: 0 0 2px gray;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.whatsapp-user {
  background-image: url("/src/assets/images/logo192.png");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  border: rgb(199, 199, 199) 1px solid;
}
.whatsapp-option {
  background-color: #e0f7d4;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  padding: 10px 15px;
  margin-top: 5px;
  max-width: 75%;
  border: 1px solid #cfcfcf;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  margin-right: 8px;
}
.whatsapp-option-self {
  background-color: #f8f8f8;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  padding: 10px 15px;
  margin-top: 5px;
  max-width: 75%;
  border: 1px solid #d3d3d3;
  text-align: left;
  font-size: 14px;
  margin-left: 8px;
  align-self: flex-start;
}
.whatsapp-options-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.whatsapp-option:hover {
  background-color: #cbffad;
}

.whatsapp-input-container {
  /* padding: 10px; */
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  margin: 8px;
}

.whatsapp-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 16px;
  background-color: #fff; /* Subtle background color */
  outline: none;
  transition: border-color 0.3s ease;
}

.whatsapp-input:focus {
  border-color: #007bff; /* Blue color on focus */
}

.bungalows-section {
  padding-top: 6rem;
}

.bungalows-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.bungalow-card {
  /* background-color: #faca6f; */
  background-color: #86c3b173;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 1.5rem;
  width: calc(
    33.333% - 1rem
  ); /* Adjust width to fit 3 cards per row with margin */
  box-sizing: border-box;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.bungalow-image {
  width: 100%;
  height: 250px;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.bungalow-card:hover .bungalow-image {
  transform: scale(1.1); /* Zoom effect */
}

.bungalow-info {
  padding: 1rem;
}

.bungalow-title {
  font-size: 1.25rem;
  margin: 0 0 0.5rem;
}

.bungalow-description {
  font-size: 1rem;
  color: #555;
}

/* Responsive adjustments */
@media (max-width: 968px) {
  .bungalow-card {
    width: calc(50% - 1rem); /* One card per row on smaller screens */
  }
  @media (max-width: 768px) {
    .bungalow-card {
      width: calc(100%); /* One card per row on smaller screens */
    }
  }
}

.hizmetler-section {
  /* background-color: #f9f9f9; */
  padding-top: 6rem;
}

.container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.hizmetler-row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  opacity: 0; /* Start hidden for animation */
  transform: translateX(-240px); /* Start position for animation */
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.hizmetler-row img {
  max-width: 80%;
  max-height: 300px;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Smooth transition for zoom effect */
}

.hizmetler-row img:hover {
  transform: scale(1.05); /* Zoom effect */
}

.hizmetler-text {
  padding: 1rem;
  flex: 1;
}

.hizmetler-text p {
  font-size: 1rem;
  line-height: 1.6;
}

.text-left {
  flex-direction: row;
}

.text-right {
  flex-direction: row-reverse;
}

.text-left.animate {
  animation: slideInFromLeft 0.6s forwards;
}

.text-right.animate {
  animation: slideInFromRight 0.6s forwards;
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-240px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(240px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hizmetler-row {
    flex-direction: column;
    text-align: center;
  }

  .hizmetler-text {
    padding: 1rem 0;
  }
}

.location-container {
  display: none;
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 1000;
}

.location-button {
  background-color: #e3602d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
}

@media (max-width: 968px) {
  .location-container {
    display: block;
  }
}
